import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputBase, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { ThemeButton } from '../../../GlobalStyles';
import CustomModal from '../../../../components/CustomModal';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ResetPasswordDialog = ({ openResetPasswordDialog, setOpenResetPasswordDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [newPassword, setNewPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const onSubmit = () => {
    if (newPassword.trim().length < 6) {
      enqueueSnackbar(translate('password_atleast_6_characters'), snackbarOptionsError);
      return;
    }

    setIsSubmitting(true);

    conObj
      .post(`password_reset/${sessionStorage.getItem('resetToken')}.json`, { 'user-new_password': newPassword.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            sessionStorage.removeItem('resetToken');
            enqueueSnackbar(translate('password_reset_success'), snackbarOptionsSuccess);
            navigate('/auth/login');
          }
        }
      })
      .catch((err) => {
        sessionStorage.removeItem('resetToken');
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenResetPasswordDialog(false);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <CustomModal open={openResetPasswordDialog} onClose={() => setOpenResetPasswordDialog(false)}>
      <Box
        sx={{
          p: '50px 24px 24px',
          m: 'auto',
          position: 'relative',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>
          {translate('reset_password')}
        </Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: '10px',
                border: 'none',
                height: '44px',
                p: '8.5px 13px',
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}
            >
              <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#fff' }} />
              <InputBase
                type="password"
                name="newPassword"
                value={newPassword}
                placeholder={translate('new_password')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Stack>
          </Box>

          <ThemeButton fullWidth loading={isSubmitting} onClick={() => onSubmit()}>
            {translate('confirm')}
          </ThemeButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

ResetPasswordDialog.propTypes = {
  openResetPasswordDialog: PropTypes.bool,
  setOpenResetPasswordDialog: PropTypes.func,
};

export default ResetPasswordDialog;
