import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputBase, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import CustomModal from '../../../../components/CustomModal';
import { ThemeButton } from '../../../GlobalStyles';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      enqueueSnackbar(translate('enter_valid_email'), snackbarOptionsError);
      return;
    }

    setIsSubmitting(true);

    conObj
      .post(`forgot.json`, { 'user-email': email.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            enqueueSnackbar(translate('email_sent'), snackbarOptionsSuccess);
          }
        }
      })
      .catch((err) => {
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenForgotPasswordDialog(false);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <CustomModal open={openForgotPasswordDialog} onClose={() => setOpenForgotPasswordDialog(false)}>
      <Box
        sx={{
          p: '50px 24px 24px',
          m: 'auto',
          position: 'relative',
        }}
      >
        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>
          {translate('forgot_password')}
        </Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderRadius: '10px',
                border: 'none',
                height: '44px',
                p: '8.5px 13px',
                backgroundColor: 'rgba(0,0,0,0.5)',
              }}
            >
              <Iconify icon={'mdi-light:email'} width={24} height={24} sx={{ color: '#fff' }} />
              <InputBase
                name="email"
                value={email}
                placeholder={translate('email')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
          </Box>

          <ThemeButton loading={isSubmitting} onClick={() => onSubmit()} fullWidth>
            {translate(`confirm`)}
          </ThemeButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
