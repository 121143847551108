// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const th = {
  game: 'เกม',
  games: 'เกม',
  sport: 'กีฬา',
  center: 'ศูนย์',
  mines: 'เกมเหมือง',
  crash: 'แครช',
  lucky_colour: 'สีโชคดี',
  dice: 'ลูกเต๋า',
  limbo: 'ลิมโบ',
  lucky_gift: 'ของขวัญโชคดี',
  all_games: 'เกมทั้งหมด',
  game_history: 'ประวัติเกม',
  coupon_code: 'รหัสคูปอง',
  telegram: 'เทเลแกรม',
  customer_service: 'บริการลูกค้า',
  player: 'ผู้เล่น',
  winner: 'ผู้ชนะ',
  all_bets: 'เดิมพันทั้งหมด',
  my_bets: 'เดิมพันของฉัน',
  name: 'ชื่อ',
  time: 'เวลา',
  bet_amount: 'จำนวนเดิมพัน',
  prediction: 'ทำนาย',
  payout: 'การจ่ายเงิน',
  login: 'เข้าสู่ระบบ',
  register: 'ลงทะเบียน',
  username_placeholder: 'ชื่อผู้ใช้',
  please_input_your_x: 'โปรดกรอก{{x}}ของคุณ',
  verification_code: 'รหัสยืนยัน',
  press_get_code: 'กดปุ่มรับรหัส เพื่อรับรหัสยืนยันทาง SMS',
  otp_sent: 'รหัสยืนยันถูกส่งแล้ว! กรอกรหัสยืนยันด้านล่างเพื่อดำเนินการต่อ',
  your_otp_expire: 'รหัส OTP ของคุณจะหมดอายุใน',
  get_code: 'รับรหัส',
  didnt_receive: 'ไม่ได้รับข้อความใช่หรือไม่?',
  send_again: 'ส่งอีกครั้ง',
  captcha: 'แคปช่า',
  forgot_password: 'ลืมรหัสผ่าน',
  minor_note: 'ในการเยี่ยมชมไซต์นี้ โปรดตรวจสอบว่าคุณมีอายุเกิน 18 ปีและยอมรับ',
  terms_of_service: 'ข้อกำหนดในการให้บริการ',
  by_signing_in: 'โดยการเข้าสู่ระบบหรือการสมัครสมาชิก คุณยอมรับ',
  dont_have_account: 'ไม่มีบัญชีใช่หรือไม่?',
  sign_up: 'สมัครสมาชิก',
  username: 'ชื่อผู้ใช้',
  email: 'อีเมล',
  full_name: 'ชื่อเต็ม',
  confirm_18: 'ฉันยืนยันว่าฉันอายุ 18 ปีขึ้นไปและได้อ่านแล้ว',
  already_have_account: 'มีบัญชีอยู่แล้วใช่หรือไม่?',
  share: 'แชร์',
  deposit: 'ฝากเงิน',
  bonus: 'โบนัส',
  app: 'แอป',
  load_more: 'โหลดเพิ่มเติม',
  details: 'รายละเอียด',
  helps: 'ความช่วยเหลือ',
  kyc_policy: 'นโยบาย KYC',
  privacy_policy: 'นโยบายความเป็นส่วนตัว',
  responsible_gaming: 'การเล่นเกมอย่างมีความรับผิดชอบ',
  terms_and_condition: 'ข้อกำหนดและเงื่อนไข',
  faq: 'คำถามที่พบบ่อย',
  about_us: 'เกี่ยวกับเรา',
  amount: 'จำนวนเงิน',
  extra: 'พิเศษ',
  deposit_bonus: 'โบนัสเงินฝาก',
  do_not_participate: 'ไม่เข้าร่วมโปรโมชั่น',
  payment_problem: 'ปัญหาการชำระเงิน',
  click_for_help: 'คลิกเพื่อขอความช่วยเหลือ',
  deposit_notes: 'บันทึกการฝากเงิน',
  available_balance: 'ยอดคงเหลือที่สามารถใช้ได้',
  withdraw: 'ถอนเงิน',
  withdrawal_notes: 'บันทึกการถอนเงิน',
  withdrawal_note1: 'จำนวนการถอนในปัจจุบัน',
  withdrawal_note1_1: 'ระดับ VIP',
  withdrawal_note2: 'ค่าธรรมเนียมการถอน:',
  min: 'ขั้นต่ำ:',
  max: 'สูงสุด:',
  opening_times: 'เวลาทำการ:',
  withdrawal_note3: '1. เวลาถอนเงินเร็วที่สุดภายใน 10 นาที',
  withdrawal_note4:
    '2. โปรดตรวจสอบให้แน่ใจว่าข้อมูลการถอนของคุณถูกต้อง หากข้อมูลการถอนผิดพลาดอาจทำให้เงินของคุณสูญหายได้',
  withdrawal_note5: '3. โปรดติดต่อเราหากมีคำถามใด ๆ',
  notifications: 'การแจ้งเตือน',
  invalid_x: '{{x}} ไม่ถูกต้อง',
  captcha_not_match: 'แคปช่าไม่ตรงกัน',
  invalid_x_must_be_atleast_y_characters: '{{x}} ไม่ถูกต้อง ต้องมีอย่างน้อย {{y}} ตัวอักษร',
  x_is_required: '{{x}} เป็นสิ่งจำเป็น',
  phone_number: 'หมายเลขโทรศัพท์',
  phone_num_x_max: 'หมายเลขโทรศัพท์สูงสุด {{x}} หลัก',
  email_sent: 'อีเมลถูกส่งแล้ว กรุณาตรวจสอบอีเมลของคุณ',
  enter_valid_email: 'กรุณาใส่อีเมลที่ถูกต้อง',
  confirm: 'ยืนยัน',
  password_atleast_6_characters: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  password_reset_success: 'รีเซ็ตรหัสผ่านสำเร็จ!',
  new_password: 'รหัสผ่านใหม่',
  bank: 'ธนาคาร',
  bank_transfer: 'โอนเงินผ่านธนาคาร',
  bank_name: 'ชื่อธนาคาร',
  bank_branch: 'สาขาธนาคาร',
  pay_id: 'Pay ID',
  account_name: 'ชื่อบัญชี',
  account_number: 'หมายเลขบัญชี',
  drop_or_select: 'วางหรือเลือกไฟล์',
  drop_files_here: 'วางไฟล์ที่นี่',
  transfer_to: 'โอนเงินไปยัง',
  upload_receipt: 'อัปโหลดใบเสร็จ',
  select_bonus: 'เลือกโบนัส',
  no_promotion: 'ไม่มีโปรโมชั่น',
  please_upload_receipt: 'กรุณาอัปโหลดใบเสร็จ',
  bank_account: 'บัญชีธนาคาร',
  add_account: 'เพิ่มบัญชี',
  account_holder_name: 'ชื่อผู้ถือบัญชี',
  name_of_the_account_holder: 'ชื่อของผู้ถือบัญชี',
  fill_in_bank_account: 'กรอกบัญชีธนาคาร',
  withdraw_information: 'ข้อมูลการถอนเงิน',
  note: 'หมายเหตุ',
  amount_withdrawn: 'จำนวนที่ถอน',
  amount_no_exceed_balance: 'จำนวนที่ถอนต้องไม่เกินยอดคงเหลือ',
  minimum_withdraw_amount_is_x: 'จำนวนการถอนขั้นต่ำคือ {{x}}',
  transaction_history: 'ประวัติการทำธุรกรรม',
  date_from: 'วันที่เริ่มต้น',
  date_to: 'วันที่สิ้นสุด',
  account_name_must_be_4: 'ชื่อบัญชีต้องมีความยาวมากกว่า 4 ตัวอักษร',
  account_number_must_be_x: 'หมายเลขบัญชีต้องมีความยาว 10-14 หลัก',
  minimum_length_is_x: 'ความยาวขั้นต่ำคือ {{x}}',
  maximum_length_is_x: 'ความยาวสูงสุดคือ {{x}}',
  payment_method: 'วิธีการชำระเงิน',
  mode: 'โหมด',
  no_bank_account: 'คุณยังไม่มีบัญชีสำหรับการถอน เพิ่มตอนนี้',
  please_select_bank: 'กรุณาเลือกบัญชีธนาคาร',

  bet_history: 'ประวัติการเดิมพัน',
  game_provider: 'ผู้ให้บริการเกม',
  bet_count: 'จำนวนการเดิมพัน',
  win_loss: 'ชนะ/แพ้',
  rebate: 'เงินคืน',
  turnover: 'ยอดหมุนเวียน',
  game_category: 'หมวดหมู่เกม',

  you_have_x_unread_messages: 'คุณมี {{x}} ข้อความที่ยังไม่ได้อ่าน',
  deposit_amount_between_x_and_y: 'จำนวนเงินฝากต้องอยู่ระหว่าง {{x}} และ {{y}}',
  minimum_amount_is_x_y: 'จำนวนเงินขั้นต่ำคือ {{x}} {{y}}',

  // all
  all: 'ทั้งหมด',
  title: 'หัวข้อ',
  date: 'วันที่',
  type: 'ประเภท',
  status: 'สถานะ',
  remark: 'หมายเหตุ',
  category: 'หมวดหมู่',
  please_select_x: 'กรุณาเลือก {{x}}',
  save: 'บันทึก',

  change_password: 'เปลี่ยนรหัสผ่าน',
  current_password: 'รหัสผ่านปัจจุบัน',
  confirm_password: 'ยืนยันรหัสผ่านใหม่',
  submit: 'ส่ง',
  password_changed: 'รหัสผ่านถูกเปลี่ยนแล้ว',
  x_must_be_y_characters: '{{x}} ต้องมีอย่างน้อย {{y}} ตัวอักษร',
  reset_password: 'รีเซ็ตรหัสผ่าน',
  casino: 'คาสิโน',
  slots: 'สล็อต',
  live_casino: 'คาสิโนสด',
  fishing: 'ยิงปลา',
  sports: 'กีฬา',
  cards: 'ไพ่',
  clear: 'ล้าง',
  search: 'ค้นหา',
  new: 'ใหม่',
  logout: 'ออกจากระบบ',
  choose_date: 'เลือกวันที่',
  select_both_date: 'เลือกทั้งวันที่เริ่มต้นและสิ้นสุด',
  from: 'จาก',
  to: 'ถึง',
  apply: 'ใช้',
  promotions: 'โปรโมชั่น',
  learn_more: 'เรียนรู้เพิ่มเติม',
  password_doesnt_match: 'รหัสผ่านไม่ตรงกัน',
  cancel: 'ยกเลิก',
  confirm_to_pay: 'ยืนยันการชำระเงิน',
  deposit_amount: 'จำนวนเงินฝาก',
  loading_please_wait: 'กำลังโหลด กรุณารอสักครู่',
  account: 'บัญชี',
  profile: 'โปรไฟล์',
  bank_list: 'รายชื่อธนาคาร',
  in_maintenance: 'อยู่ระหว่างการบำรุงรักษา',
  birthday: 'วันเกิด',
  birthday_bonus: 'กรอกวันเกิดของคุณเพื่อรับโบนัส! 🎉🎁',
  referral_code: 'รหัสอ้างอิง',
  referral: 'การอ้างอิง',
  my_referral: 'การอ้างอิงของฉัน',
  my_claim: 'การอ้างสิทธิ์ของฉัน',
  claim_profit: 'อ้างสิทธิ์กำไร',
  available_profit: 'กำไรที่ใช้ได้',
  claimed_profit: 'กำไรที่อ้างสิทธิ์แล้ว',
  all_time_profit: 'กำไรทั้งหมด',
  referral_note_on_x: 'รับกำไรทุกครั้งที่เพื่อนของคุณเล่นใน {{x}}',
  tier: 'ระดับ',
  commission: 'ค่าคอมมิชชั่น',
  referred_member: 'สมาชิกที่อ้างอิง',
  profit_earned: 'กำไรที่ได้รับ',
  account_information: 'ข้อมูลบัญชี',
  invite_friends: 'เชิญเพื่อน',
  how_it_work: 'ทำงานอย่างไร?',
  invite_note: 'รับโบนัสพิเศษจากการอ้างอิงเมื่อเพื่อนของคุณสมัครผ่านรหัสอ้างอิงของคุณ!',
  share_via: 'แชร์ผ่าน',
  referral_link: 'ลิงค์อ้างอิง',
  total_profit: 'กำไรรวม',
  copied: 'คัดลอกแล้ว',
  nothing_to_claim: 'ไม่มีสิ่งใดให้เรียกร้อง',
  claim_history: 'ประวัติการอ้างสิทธิ์',
  downline: 'ดาวน์ไลน์',
  last_x_digit_bank_ref_number: 'เลขท้าย {{x}} หลักของเลขอ้างอิงธนาคาร',
  bank_ref_number_validation_x: 'จำเป็นต้องระบุเลขอ้างอิงธนาคารและต้องมีความยาว {{x}} หลัก',
  sort: 'จัดเรียง',
  login_directly: 'เข้าสู่ระบบโดยตรงด้วย',
  our_game_provides: 'ผู้ให้บริการเกมของเรา',
  refer_and_reward: 'อ้างอิง & รับรางวัล',
  linked_successfully_x: 'ลิงค์สำเร็จกับ {{x}}! กรุณาลงทะเบียนบัญชีของคุณเป็นครั้งแรก',
  more_x_of_y: 'เพิ่มเติม {{x}}/{{y}}',
  default: 'ค่าเริ่มต้น',
  promotion: 'โปรโมชั่น',
  daily_mission: 'ภารกิจประจำวัน',
  agent: 'ตัวแทน',
  contact_us: 'ติดต่อเรา',
  download: 'ดาวน์โหลด',
  cash_claim: 'เรียกร้องเงินสด',
  demo: 'ตัวอย่าง',
  favorites: 'รายการโปรด',
  popular: 'ยอดนิยม',
  claim_bonus: 'รับโบนัส',
  insufficient_balance: 'ยอดคงเหลือไม่เพียงพอ',
  currently_displaying: 'กำลังแสดง {{x}} {{y}} เกมจากทั้งหมด {{z}}',
  all_x: 'ทั้งหมด {{x}}',
  no_results: 'ไม่พบผลลัพธ์',
  search_games: 'ค้นหาเกม',
  please_scan_using_your: 'กรุณาสแกนโดยใช้',
  vip_level: 'ระดับวีไอพี',
  bonus_direct: 'โบนัสโดยตรง',
  rebate_level: 'ระดับเงินคืน',
  vip_header:
    'การเดิมพันทั้งหมดที่วางไว้ในเครื่องสล็อต, เกมกระดานและการ์ด, เกมตกปลา, วิดีโอ, กีฬาและลอตเตอรี่ที่ Playboy99 จะถูกสะสมอย่างถาวร เดิมพันที่ถูกต้อง = รายได้ถาวรและโชคลาภ!',
  vip_example:
    'ตัวอย่างที่ 1: สมาชิกที่สะสมยอดฝากครบ 1 ล้าน ถึง VIP ระดับ 2 รับโบนัส 210 เรียลและเงินคืน 3.50% เมื่อสะสมยอดฝากครบ 6 ล้าน ถึง VIP ระดับ 3 รับโบนัส 800 เรียล และเงินคืน 4%',
  activity_rules: 'กติกากิจกรรม:',
  activity_rules_1: '1. โบนัสทั้งหมดจำเป็นต้องทำยอดเทิร์นโอเวอร์ x3 เพื่อขอถอนเงิน',
  activity_rules_2: '2. โปรดรับโบนัสเงินคืนภายในระยะเวลาที่กำหนด หากล่าช้าจะถือว่าสละสิทธิ์',
  activity_rules_3:
    '3. การเข้าร่วมกิจกรรมของสมาชิกจะถูกนับอัตโนมัติโดยระบบ หากมีข้อโต้แย้ง ผลการตรวจสอบของ Playboy99 จะเป็นที่สิ้นสุด',
  activity_rules_4:
    "4. หากลืมบัญชีหรือรหัสผ่าน โปรดติดต่อ 'ฝ่ายบริการลูกค้าออนไลน์ตลอด 24 ชั่วโมง' เพื่อช่วยกู้คืนข้อมูลบัญชี",
  activity_rules_5: '5. Playboy99 ขอสงวนสิทธิ์ในการเปลี่ยนแปลง หยุดหรือยกเลิกโปรโมชั่นนี้ได้ตลอดเวลา',
  activity_rules_6: '6. ห้ามบันทึกเกมฟรี ซื้อเกมฟรี',
  activity_rules_7: '7. ไม่สามารถรวมกับโบนัสอื่นได้',
  activity_rules_8: '8. จำนวนถอนสูงสุดต่อการฝากคือ 100,000 เรียล',
  event_instructions: 'คำแนะนำการจัดงาน',
  agent_p1: 'แชร์ลิงก์เฉพาะของคุณในแอปและโซเชียลมีเดีย',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube และอื่นๆ รีบเชิญทุกคน สมาชิกที่ลงทะเบียนผ่านลิงก์พิเศษของคุณจะกลายเป็นตัวแทนตรงของคุณ แต่ต้องใส่ใจเพราะสมาชิกใหม่ที่คุณเชิญต้องเป็นของแท้และถูกต้อง',
  agent_step1:
    'ได้รับการเชิญเพื่อน ฝากเงินครั้งแรกสำเร็จ คุณสามารถชนะโบนัส 5 เรียล ตัวอย่างเช่น หากคุณเชิญ 10 คนสำหรับการฝากครั้งแรก คุณสามารถรับ 50 เรียล ยิ่งเชิญมาก ยิ่งได้รับโบนัสมาก คุณสามารถดูประวัติของคุณและลงทะเบียนในหน้าส่งเสริมโบนัสและค่าคอมมิชชั่นสูง',
  agent_step2:
    'หากแขกของคุณเดิมพัน 1 เรียลหรือมากกว่า คุณสามารถเพลิดเพลินกับค่าคอมมิชชั่นสูง! คุณสามารถรับค่าคอมมิชชั่นตลอดชีวิตสูงสุด 5% และการเชิญตลอดชีวิต! ระบบจะตั้งค่าค่าคอมมิชชั่นของวันก่อนในเวลา 07.00 น. ของวันถัดไป',
  agent_step2_1: 'วิธีรับ: ล็อกอิน - โปรโมชั่นเชิญ - เก็บค่าคอมมิชชั่น',
  agent_table1: 'อัตราค่าคอมมิชชั่นการเดิมพันสำหรับสล็อต, ตกปลาและบล็อกเชนมีดังนี้:',
  agent_table1_1: 'ลูกค้าฝาก 20 เดิมพัน 200',
  person: 'คน',
  agent_table2_title1: 'การเดิมพันของสมาชิก',
  agent_table2_title2: 'สล็อตเกมเท่านั้น',
  agent_table2_example:
    'ตัวอย่าง: คุณมีสมาชิก 100 คน และมูลค่าการเดิมพันจริงรวมประมาณ 1 ล้านเรียลต่อวัน ค่าคอมมิชชั่นที่คุณได้รับในวันนั้นคือ: 1,000,000 x 1.20% 12,000 เรียล รายได้ต่อเดือน 100,000 ง่ายมากที่จะทำได้',
  reminder: 'เตือนความจำ',
  agent_reminder:
    'บัญชีสมาชิกที่มี IP เดียวกัน, อุปกรณ์เดียวกัน, ชื่อ, บัตรธนาคารและข้อมูลอื่น ๆ สามารถเข้าร่วมได้เพียงครั้งเดียว และใครก็ตามที่ละเมิดกฎจะไม่ได้รับโบนัสนี้ หรือกลุ่มจะถูกยึดและบัญชีจะถูกระงับอย่างถาวร',
  agent_step3_1:
    'การเข้าร่วมกิจกรรมของสมาชิกจะถูกนับอัตโนมัติโดยระบบ หากมีข้อโต้แย้ง ผลการวิเคราะห์ที่ดำเนินการโดย Playboy99 จะเป็นที่สิ้นสุด',
  agent_step3_2:
    'หากคุณลืมบัญชีสมาชิก/รหัสผ่าน โปรดติดต่อทีมบริการลูกค้าตลอด 24 ชั่วโมงของเราเพื่อช่วยคุณกู้คืนข้อมูลบัญชี',
  agent_step3_3: 'Playboy99 ขอสงวนสิทธิ์ในการเปลี่ยนแปลง, หยุดหรือยกเลิกโปรโมชั่นได้ตลอดเวลา',
  agent_step3_4: 'ตัวแทนสามารถรับโบนัสค่าคอมมิชชั่นในรูปแบบเงินสดหรือเครดิตในกระเป๋า',
  agent_step3_5: 'ห้ามบันทึกเกมฟรี, ซื้อเกมฟรี',
  agent_step3_6: 'ไม่สามารถรวมกับโบนัสและเงินฝากใด ๆ',
  agent_step3_7: 'ใช้ได้เฉพาะกับสล็อตเกมเท่านั้น',
  promo_concluded: 'โปรโมชั่นสิ้นสุดแล้ว! กลับมาอีกครั้งในวันพรุ่งนี้!',
  can_only_claim: 'สามารถขอรับโบนัสได้เฉพาะระหว่าง {{x}} ถึง {{y}} เวลาในบราซิล',
  please_login: 'โปรดล็อกอินก่อนเพื่อขอรับโบนัส!',
  back_to_home: 'กลับสู่หน้าหลัก',
  bonus_claimed: 'โบนัสที่ขอรับแล้ว!',
  terms_n_conditions: 'ข้อกำหนดและเงื่อนไข',
  claim_now: 'ขอรับเดี๋ยวนี้',
  the_activity_x_in: 'กิจกรรม {{x}} ใน',
  starts: 'เริ่มต้น',
  ends: 'สิ้นสุด',
  list_of_winners: 'รายชื่อผู้ชนะ',
  days: 'วัน',
  hours: 'ชั่วโมง',
  minutes: 'นาที',
  seconds: 'วินาที',
  total_amount_received: 'ยอดรวมที่ได้รับ',
  deposit_fee: 'ค่าธรรมเนียมการฝาก',
  get: 'รับ',
  apply_now: 'สมัครเดี๋ยวนี้',
  number_exists: 'หมายเลขโทรศัพท์นี้ลงทะเบียนแล้ว!',
  number_not_exists: 'หมายเลขโทรศัพท์นี้ไม่ได้ลงทะเบียน!',
  payment_draft: 'หากคุณยืนยันว่าคุณได้ชำระเงินแล้ว โปรดรอสักครู่ เนื่องจากการชำระเงินอาจยังอยู่ในระหว่างดำเนินการ',
  my_downline: 'สมาชิกใต้ของฉัน',
  downline_name: 'ชื่อสมาชิกใต้',
  date_joined: 'วันที่เข้าร่วม',
  deposit_amount_x_days: 'จำนวนเงินฝาก ({{x}} วัน)',
  dont_show_again: 'อย่าแสดงอีก',
  next: 'ถัดไป',
  close: 'ปิด',
  no_balance: 'ไม่มีเงินในบัญชี',
  menu: 'เมนู',
  vip_club: 'คลับ VIP',
  home: 'หน้าแรก',
  lobby: 'ล็อบบี้',
  show_all: 'แสดงทั้งหมด',
  game_name: 'ชื่อเกม',
  sub_filters: 'ตัวกรองย่อย',
  contact_us_24: 'ติดต่อเราตลอด 24 ชั่วโมง',
  live_chat: 'แชทสด',
  chat_now: 'แชทตอนนี้',
  gaming_license: 'ใบอนุญาตการเล่นเกม',
  certification: 'การรับรอง',
  security: 'ความปลอดภัย',
  threatmetrix: 'ThreatMetrix',
  gaming_license_caption:
    'Playboy99 เป็นองค์กรที่มีใบอนุญาตและอยู่ภายใต้การกำกับดูแลโดยรัฐบาลของคูราเซา และดำเนินการอย่างถูกต้องตามใบอนุญาตหลักของผู้ให้บริการเกม, N.V #365/JAZ',
  no_limits: 'ไม่มีข้อจำกัด',
  vip_description_no_limits:
    'เรามีบริการ VIP ส่วนตัวที่มีเงื่อนไขการเล่นที่ไม่เหมือนใครสำหรับผู้เล่นทั้งหมดในคลับ VIP ตอนนี้คุณสามารถเล่นได้โดยไม่ต้องกังวล! มันง่ายมาก - เล่นเพื่อความตื่นเต้น, พัฒนาทักษะการเล่นของคุณ, สนุกกับเวลาและไม่มีข้อจำกัดในโอกาสการชนะของคุณ!',
  financial_privileges: 'สิทธิประโยชน์ทางการเงิน',
  vip_description_financial_privileges:
    'คลับ VIP ยินดีต้อนรับผู้เล่นที่ต้องการรับบริการ VIP คุณภาพสูง สิทธิพิเศษ, โปรโมชั่นพิเศษและผลประโยชน์มีให้สำหรับคุณที่เว็บไซต์ของเรา! สถานะผู้เล่น VIP จะปลดล็อกขีดจำกัดการถอนเงินที่สูงขึ้นในระยะเวลาที่สั้นที่สุด! เพลิดเพลินไปกับมัน!',
  personal_manager: 'ผู้จัดการส่วนตัว',
  vip_description_personal_manager:
    'คุณต้องการรับข้อได้เปรียบใดเมื่อคุณกลายเป็นผู้เล่น VIP? แชร์ความคิดเห็นของคุณกับผู้จัดการส่วนตัวและบอกให้เราทราบว่ายังมีอะไรอีกที่คุณต้องการเห็นที่เว็บไซต์ของเรา! เราเปิดกว้างสำหรับข้อเสนอแนะที่ดีเสมอ!',
  vip_promotions: 'โปรโมชั่น VIP',
  vip_description_vip_promotions:
    'คุณต้องการรับโปรโมชั่นที่ดีมากขึ้นหรือไม่? ผู้เล่น VIP ที่มีความซื่อสัตย์จะเพลิดเพลินไปกับสิทธิประโยชน์และโปรโมชั่นมากมายเป็นรางวัลสำหรับความซื่อสัตย์! เป็นหนึ่งในพวกเขา!',
  withdraw_limit: 'ขีดจำกัดการถอน',
  monthly: 'รายเดือน',
  weekly: 'รายสัปดาห์',
  annual: 'รายปี',
  cashback: 'เงินคืน',
  personal_account_manager: 'ผู้จัดการบัญชีส่วนตัว',
  yes: 'ใช่',
  join_the_vip: 'เข้าร่วมโปรแกรม VIP ตอนนี้!',
  join_now: 'เข้าร่วมเดี๋ยวนี้',
  play_the_way_you_want: 'เล่นตามที่คุณต้องการ',
  more_info: 'ข้อมูลเพิ่มเติม',
  nothing_to_show: 'ไม่มีอะไรให้แสดง',
  remarks: 'หมายเหตุ',
  password: 'รหัสผ่าน',
  back: 'กลับ',
  new_to_Playboy99: 'ใหม่กับ Playboy99',
  create_an_account: 'สร้างบัญชี',
  withdraw_amount: 'จำนวนเงินที่ถอน',
  balance: 'ยอดเงิน',
  bank_bsb: 'ธนาคาร BSB',
  enter_bank_branch: 'กรอกสาขาธนาคาร',
  enter_pay_id: 'กรอก Pay ID',
  level: 'ระดับ',
  claimed: 'ถูกเรียกร้อง',
  not_found: 'ไม่พบ',
  become_a_vip: 'กลายเป็น VIP',
  otp_not_found: 'OTP ยังไม่ได้ตั้งค่า',
  resend_again: 'ส่งอีกครั้ง',
  how_to_get_referral_bonus: 'วิธีรับโบนัสชวนเพื่อน?',
  steps_to_claim: 'ขั้นตอนในการขอรับโบนัส',
  share_your_referral_code: '1. แชร์รหัสอ้างอิงของคุณกับเพื่อนเพื่อสมัครบัญชีผ่าน Facebook, Twitter, หรือ WhatsApp.',
  after_friend_register:
    '2. หลังจากที่เพื่อนสมัครกับ Playboy99, ทั้งผู้แนะนำและสมาชิกที่ถูกแนะนำต้องทำการฝากเงินเพื่อขอรับโบนัสนี้.',
  refer_a_friend_bonus: '3. การคำนวณโบนัสชวนเพื่อน:',
  turnover_played: 'ยอดเดิมพันที่เล่น x ค่าคอมมิชชั่น % = โบนัสชวนเพื่อน',
  example: 'ตัวอย่าง:',
  turnover_formula_x: 'ยอดเดิมพัน (1,000,000) x 0.002% (ค่าคอมมิชชั่น %) = 2,000{{x}}',
  note_bonus_only: '*หมายเหตุ: โบนัสสามารถขอรับได้ในวันถัดไปหลังเวลา 12:00 น.',
  note_bonus_only_x: '*หมายเหตุ: โบนัสสามารถขอรับได้ในวันถัดไปหลังเวลา {{x}}',
  access_dashboard:
    '4. เข้าถึงแดชบอร์ดเพื่อตรวจสอบกำไรที่มีสิทธิ, ระดับค่าคอมมิชชั่น, ประวัติการเรียกร้อง และสมาชิกใต้',
  click_this_button_to_claim: 'คลิกปุ่มนี้เพื่อขอรับโบนัสชวนเพื่อน',
  commision_tier: 'ระดับค่าคอมมิชชั่น',
  member_downline: 'สมาชิกใต้',
  verified_by_play_protect: 'ได้รับการตรวจสอบโดย Play Protect',
  reviews: '26K รีวิว',
  reviews_value: '4M+',
  downloads: 'ดาวน์โหลด',
  rated_for: 'จัดอันดับสำหรับ 18+',
  install: 'ติดตั้ง',
  add_to_wishlist: 'เพิ่มในรายการที่ต้องการ',
  about_this_app: 'เกี่ยวกับแอปนี้',
  app_description_1:
    'ยินดีต้อนรับสู่ Playboy99 แพลตฟอร์มคาสิโนออนไลน์ที่ดีที่สุดในบังกลาเทศ ซึ่งความตื่นเต้นและโอกาสในการชนะไม่มีที่สิ้นสุด! ไม่ว่าคุณจะเป็นแฟนเกมคาสิโนคลาสสิกหรือการเดิมพันที่ทันสมัย เรามีสิ่งที่ตอบโจทย์ทุกคน ลองสัมผัสความตื่นเต้นจากเกมที่หลากหลายของเรา รวมถึงสล็อต, แบล็คแจ็ค, รูเล็ต, โป๊กเกอร์ และอื่นๆ อีกมากมาย—ทั้งหมดนี้จากความสะดวกสบายของอุปกรณ์เคลื่อนที่ของคุณ.',
  app_description_2:
    'ที่ Playboy99 เราให้ความสำคัญกับความปลอดภัยและประสบการณ์การเล่นเกมของคุณ แพลตฟอร์มของเราได้รับใบอนุญาตอย่างเต็มที่และมีความปลอดภัย ทำให้มั่นใจได้ถึงการเล่นที่ยุติธรรมและการเล่นเกมอย่างมีความรับผิดชอบตลอดเวลา เราใช้เทคโนโลยีขั้นสูงเพื่อปกป้องข้อมูลส่วนบุคคลของคุณและมอบประสบการณ์การเล่นเกมที่ราบรื่นด้วยการฝากเงินที่รวดเร็ว การถอนเงินที่รวดเร็ว และบริการลูกค้าตลอด 24 ชั่วโมง.',
  why_choose_brand: 'ทำไมต้องเลือก Playboy99?',
  reason_1: 'เกมมากมายจากผู้ให้บริการเกมชั้นนำ',
  reason_2: 'อินเตอร์เฟซที่ใช้งานง่ายและนำทางสะดวก',
  reason_3: 'โบนัสและโปรโมชั่นพิเศษที่ปรับแต่งเฉพาะสำหรับคุณ',
  reason_4: 'ตัวเลือกการชำระเงินที่ปลอดภัยสำหรับการทำธุรกรรมที่รวดเร็วและปลอดภัย',
  reason_5: 'มีให้บริการในภาษาบังกาลีและภาษาอังกฤษเพื่อตอบสนองความชอบของคุณ',
  app_description_3:
    'เข้าร่วมกับผู้เล่นที่พึงพอใจหลายพันคนวันนี้และเข้าร่วมประสบการณ์คาสิโนออนไลน์ที่เชื่อถือได้ที่สุดในบังกลาเทศ! ไม่ว่าคุณจะมาที่นี่เพื่อความตื่นเต้นของเกมหรือโอกาสในการชนะใหญ่ Playboy99 คือประตูของคุณสู่ความบันเทิงที่ไม่มีที่สิ้นสุด.',
  optional: 'เลือกได้',
  deposit_from_bank: 'ฝากจากธนาคาร',
  app_download: 'ดาวน์โหลดแอป',
  download_message: 'ดาวน์โหลดแอปของเราวันนี้!',
  play: 'เล่น',
  wallet: 'กระเป๋าเงิน',
  contact: 'ติดต่อ',
  enter_the_amount: 'กรอกจำนวนเงิน',
  deposit_note_txt_1: 'ใช้บัญชีที่ลงทะเบียนสำหรับการทำธุรกรรมเท่านั้น.',
  withdrawal_condition: 'เงื่อนไขการถอน',
  requirement: 'ข้อกำหนด',
  confirm_deposit: 'ยืนยันการฝากเงิน',
  member_level: 'ระดับสมาชิก',
  points_required: 'คะแนนที่ต้องการ',
  language: 'ภาษา',
  history: 'ประวัติ',
  bet: 'เดิมพัน',
  select_date: 'เลือกวันที่',
  popularity: 'ความนิยม',
  user: 'ผู้ใช้',
  providers: 'ผู้ให้บริการ',
  password_not_match: 'รหัสผ่านไม่ตรงกัน',
  must_be_same_as_bank_account_name: `จะต้องเหมือนกับชื่อบัญชีธนาคารของคุณ`,
  must_start_with: `หมายเลขโทรศัพท์ต้องมีอย่างน้อย 10 หลักและขึ้นต้นด้วย 0`,
};

export default th;
