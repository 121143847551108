import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { countries } from '../_mock';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_SELECTIONS } from '../config';
import { checkAndFormatPhoneNumber } from '../utils/formatNumber';
import useLocales from '../hooks/useLocales';
import { StyledInput, StyledTextField } from '../layouts/GlobalStyles';
import Iconify from './Iconify';
import palette from '../theme/palette';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  const foundCode = countries.find((x) => x?.code === code);
  return foundCode || null;
};

const PhonenumberTextField2 = ({ onChange, value, selectCode = () => {}, selectAreaCode = () => {}, ...others }) => {
  const [callingCode, setCallingCode] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');
  const { translate } = useLocales();
  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  const allowedCountries = ['TH'];
  const defaultCountryCode = 'TH';

  const filteredCountries = useMemo(() => {
    return countries.filter((x) => allowedCountries.includes(x?.code));
  }, []);

  const optionalCode = useMemo(
    () =>
      callingCode ||
      getCountryCodeObj(checkAndFormatPhoneNumber(value || '').countryCode) ||
      getCountryCodeObj(defaultCountryCode),
    [callingCode, value]
  );

  const optionalValue = useMemo(
    () => textFieldValue || checkAndFormatPhoneNumber(value || '').formattedNumber || '',
    [textFieldValue, value]
  );

  // const handleOpenMenu = () => {
  //   setOpenCodeSelect(true);
  // };

  // const handleCloseMenu = () => {
  //   setOpenCodeSelect(false);
  // };

  // const handleCallingCodeChange = (value) => {
  //   setCallingCode(value);
  //   selectCode(value?.code);
  //   selectAreaCode(value?.phone);
  //   handleCloseMenu();
  //   if (optionalValue) {
  //     onChange(`+${value.phone}${optionalValue}`);
  //   }
  // };

  const handleTextFieldChange = useCallback(
    (event) => {
      const numbers = /^[0-9._]+$/;

      console.log(`event.target.value = ${event.target.value}`);

      if (event.target.value && !event.target.value.match(numbers)) {
        return false;
      }

      setTextFieldValue(event.target.value);

      /**
       * Following should be valid
       * starts with:
       * - 06
       * - 08
       * - 09
       * - 666
       * - 668
       * - 669
       *
       * length checking withou above prefixes is
       * example: 812345678
       * but must start with 6 or 8 or 9
       */

      // const finalValue = event.target.value ? `+${optionalCode?.phone}${event.target.value}` : '';
      const finalValue = validateThaiPhoneNumber(event.target.value);
      console.log(`finalValue = ${finalValue} `);
      if (finalValue) {
        onChange(finalValue);
      } else {
        onChange(event.target.value);
      }
    },
    [optionalCode, onChange]
  );

  const validateThaiPhoneNumber = (phoneNumber) => {
    // Check if it is a valid Thai number
    if (!isValidPhoneNumber(phoneNumber, 'TH')) return false;

    // Check for specific patterns
    const startsWith = phoneNumber.slice(0, 2);
    // const startsWithThree = phoneNumber.slice(0, 3);

    if (
      (startsWith === '06' && phoneNumber.length === 10) ||
      (startsWith === '08' && phoneNumber.length === 10) ||
      (startsWith === '09' && phoneNumber.length === 10)
    ) {
      return phoneNumber;
    }
    // if (
    //   (startsWithThree === '666' && phoneNumber.length === 11) ||
    //   (startsWithThree === '668' && phoneNumber.length === 11) ||
    //   (startsWithThree === '669' && phoneNumber.length === 11)
    // ) {
    //   return `0${phoneNumber.slice(2, phoneNumber.length)}`;
    // }
    return false;
  };

  return (
    <>
      <StyledTextField
        value={optionalValue}
        sx={{ position: 'relative' }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          readOnly: others?.readOnly,
          // startAdornment: (
          //   <InputAdornment
          //     position="start"
          //     sx={{
          //       position: 'relative',
          //       color: palette.visitor.input.color,
          //       font: 'inherit',
          //       fontSize: '0.8rem',
          //       fontWeight: 500,
          //       ml: 1,
          //     }}
          //   >
          //     {/* <Button
          //       variant="text"
          //       size="small"
          //       onClick={handleOpenMenu}
          //       endIcon={<Iconify icon={'mingcute:down-fill'} />}
          //       sx={{ mr: 0.5, color: palette.visitor.input.color, font: 'inherit', py: 0, fontSize: '0.8rem' }}
          //       tabIndex={-1}
          //     >
          //       +{optionalCode?.phone}
          //     </Button> */}
          //     {/* +{optionalCode?.phone} */}
          //   </InputAdornment>
          // ),
          sx: {
            marginLeft: '8px',
            ...others?.InputPropStyles,
          },
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      {/* <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
      >
        <CardContent>
          <Autocomplete
            fullWidth
            value={optionalCode}
            options={filteredCountries}
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCallingCodeChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} label="Country Code" />}
          />
        </CardContent>
      </CustomModal> */}
    </>
  );
};

PhonenumberTextField2.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  selectCode: PropTypes.func,
  selectAreaCode: PropTypes.func,
};

export default PhonenumberTextField2;
